@media screen and (max-width: 400px) {
  #about,
  #menu,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
  img {
    max-width: 100%; /* Ensures images do not exceed the width of their container */
    height: auto; /* Maintains aspect ratio */
    object-fit: cover; /* Adjusts the image to cover the container if needed */
  }
}
